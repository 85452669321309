import { Link } from "react-router-dom";
import "./index.scss";

export default function Inscrevase() {
  return (
    <div className="comp-inscrevase">
      <img
        className="img-ondas"
        src="./assets/images/ondasLaterais_dir.svg"
        alt="Ondas azuis"
      />
      
      <div className="content">
        {/* <h1>Inscreva-se no nosso site ou acessando o nosso bot no WhatsApp</h1> */}
        <h1>Inscreva-se agora mesmo!</h1>
        <div className="botoes">
          <Link to='/inscricao' className="insc">Inscreva-se</Link>
          {/* <Link className="what"> <img src="/assets/images/icon-what.svg" alt="" /> Whatsapp</Link> */}
        </div>
      </div>
    </div>
  );
}
