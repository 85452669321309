import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import CountdownRenderer from "./contador";
import { Home, Info, LogIn } from "lucide-react";

export default function Capa({ exibeContador, exibirBotãoInscrevase }) {
  const dataFeira = new Date("2024-09-21T09:30:00");
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("Inicio");

  const navigation = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const menuLateral = document.querySelector(".menu-lateral");
      if (menuOpen && menuLateral && !menuLateral.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);



  return (
    <div>
      <div className="pagina-capa">
        <header>
          <Link to='/'>
          <img
            className="logo"
            src="/assets/images/logofrei_fundo.svg"
            alt="logo nova do frei"
            />
            </Link>
          <nav className={`content ${menuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <Link
                  to="/"
                  onClick={() => setActiveLink("Inicio")}
                  className={activeLink === "Inicio" ? "active" : ""}
                >
                  Início
                </Link>
              </li>
              <li className="login">
                <Link
                  to="/"
                  onClick={() => setActiveLink("Fazer login")}
                  className={`log ${
                    activeLink === "Fazer login" ? "active" : ""
                  }`}
                >
                  Área Admin
                </Link>
              </li>
            </ul>
          </nav>

          <button className="menu-burguer" onClick={toggleMenu}>
            &#9776;
          </button>
        </header>

        <div className={`menu-lateral ${menuOpen ? "open" : ""}`}>
          <button className="close-btn" onClick={toggleMenu}>
            &times;
          </button>
          <ul>
            <li>
              <Link
                onClick={() => {
                  setActiveLink("Inicio");
                  toggleMenu();
                }}
                className={"active"}
                to="/"
              >
                <Home /> Início
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setActiveLink("Fazer login");
                  toggleMenu();
                }}
                className={`log active`}
                to="/"
              >
                <LogIn /> Área Admin
              </Link>
            </li>
          </ul>
        </div>

        <div className="secao">
          <div className="enunciadoFeira">
            <h4 className="instituto">Instituto Social Nossa Senhora de Fátima</h4>

            <div className="feiraTitulo">
              <h1>4ª</h1>
              <h2>Feira</h2>
            </div>
            <h2 className="prof">de Profissões</h2>

            <h3>O FUTURO ESTÁ A BORDO</h3>
            <h4>21 DE SETEMBRO DE 2024</h4>

            {exibirBotãoInscrevase && (
              <button className="inscreva" onClick={() => navigation("/inscricao")}>
                Inscreva-se
              </button>
            )}
          </div>
        </div>
      </div>
      
      {exibeContador && (
        <Countdown date={dataFeira} renderer={CountdownRenderer} />
      )}
    </div>
  );
}
