import "./index.scss"
import { useEffect, useState } from "react"

export default function FeiraEvento(){
    const [slide,setSlide] = useState(0);
    const [intervalo, setIntevalo] = useState(null);

    const slide_eventos = [
        {
            img: "./assets/images/first image - events.png",

            title: "O que você verá no evento?",

            text: "O evento contará com a presença de profissionais de diversas áreas, oficinas práticas e stands interativos, oferecendo um espaço único para esclarecer dúvidas, obter orientações e conhecer o desenvolvimento dos nossos alunos!",

            botão1:"./assets/images/ball dark.png", 
            botão2:"./assets/images/ball light.png",
            botão3:"./assets/images/ball light.png"
        },
        {
            img: "./assets/images/second image - events.png",

            title: "Desenvolvimento dos nossos alunos!",

            text: "Todas as apresentações que você presenciará foram pensadas, moldadas e preparadas por cada um dos cursos. Além de poder participar e observar os projetos, poderá ser uma oportunidade para conhecer melhor aquilo que gosta e deseja para seu futuro.",

            botão1:"./assets/images/ball light.png",
            botão2:"./assets/images/ball dark.png",
            botão3:"./assets/images/ball light.png"

        },
        {
            img: "./assets/images/third image - events.png",

            title: "Venha conhecer!",

            text: "Se você está em busca de orientação profissional ou simplesmente quer explorar diferentes possibilidades de carreira, não perca esta chance! A Feira de Profissões é gratuita e aberta ao público de todas as idades.",

            botão1:"./assets/images/ball light.png",
            botão2:"./assets/images/ball light.png",
            botão3:"./assets/images/ball dark.png"

        }
    ]

    function Slide1(){
        setSlide(slide === 0 ? slide_eventos.length - 1 : slide - 1);
    };
    
    function Slide2(){
        setSlide(slide === slide_eventos.length - 1 ? 0 : slide + 1);
    };
  
    function Ball_slide(index) {
        setSlide(index);
    }

    useEffect(() => {
        const intervalo_tempo = setInterval(() => {
            setSlide((Slide1) =>
            Slide1 === slide_eventos.length - 1 ? 0: Slide1 + 1
            );  
        },13000 );

        setIntevalo(intervalo_tempo);

        return () => clearInterval(intervalo_tempo)
    }, [slide_eventos.length]);

return(
    <div className="container-feira-profissoes" id="secao-informacoes"> 


        <div className="title_feira">
            <h1 className="o-que-tera">Feira de Profissões</h1>
        </div>

        <div className="slide-show">
            <img
            src="./assets/images/arrowleft.png"
            alt=""
            className="left-arrow" 
            onClick={Slide1} 
            />
            <img  src={slide_eventos[slide].img} alt="" />
            
            <div className="balls_click">
                <img onClick={() => Ball_slide(0)} className="ball_one" src={slide_eventos[slide].botão1} alt="" />
                <img onClick={() => Ball_slide(1)} className="ball_two" src={slide_eventos[slide].botão2} alt="" />
                <img onClick={() => Ball_slide(2)} className="ball_three" src={slide_eventos[slide].botão3} alt="" />
            </div>
            
            <div className="title_text">
                <h2>{slide_eventos[slide].title}</h2>
                <p>{slide_eventos[slide].text}</p>
            </div>
            
            <img 
            src="./assets/images/arrowright.png"
            alt=""
            className="right-arrow" 
            onClick={Slide2} 
            />
        </div>
    </div>
)

}