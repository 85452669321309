import './index.scss';
import Parceiros from './comps/parceiros';
import Feira from './comps/eventos';
import Faq from './comps/faq';
import Inscrevase from './comps/inscrevase';
import Capa from '../../components/capa';
import Explore from './comps/explore';
import Video from './comps/video';
import Andares from './comps/andares';
import Rodape from '../../components/rodape';
import Acesse from './comps/acesse';


export default function Home() {
  return (
    <div className="pagina-home">
      
      <Capa exibeContador exibirBotãoInscrevase />
      <Feira />
      <Explore />
      <Video />
      <Andares />
      <Inscrevase />
      <Acesse />
      <Parceiros />
      <Faq />
      <Rodape />
      
    </div>
  );
}

