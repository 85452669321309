import { Link } from "react-router-dom";
import "./index.scss";
import { Instagram } from "lucide-react";

export default function Acesse() {
  return (
    <div className="comp-acesse">

      <img
        className="img-ondas"
        src="./assets/images/ondasLaterais_dir.svg"
        alt="Ondas azuis"
      />

      <div className="content">
        <h1>
          Acesse o Instagram do Instituto para ficar por dentro de todas
          nossas informações
        </h1>
        <div className="botoes">
          <a target="_blank" className="insc" href="https://www.instagram.com/institutonsfatima/">  
            <Instagram /> @institutonsfatima
          </a>
        </div>
      </div>
    </div>
  );
}
