import './index.scss';
import React, { useState, useEffect } from 'react';

export default function Video() {
  const referenciaVideo = React.createRef();
  const [estaTocando, setEstaTocando] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleCliqueVideo = () => {
    if (estaTocando) {
      referenciaVideo.current.pause();
      setEstaTocando(false);
      setIsButtonClicked(false);
    } else {
      referenciaVideo.current.play();
      setEstaTocando(true);
      setIsButtonClicked(true);
    }
  };

  useEffect(() => {
    if (referenciaVideo.current) {
      referenciaVideo.current.addEventListener('pause', () => setEstaTocando(false));
      referenciaVideo.current.addEventListener('play', () => setEstaTocando(true));
    }
  }, [referenciaVideo]);

  return (
    <div className="secao_video" id='secao-video'>

      <div className='sec'>
        <video
          ref={referenciaVideo}
          src="assets/videos/Conheca_nossa_escola720P_HD.mp4"
        />
        
          <img 
            className={`play ${isButtonClicked ? 'button-clicked' : ''}`}
            src={estaTocando ? "assets/images/pause_white_button_icon_227845.webp" : "assets/images/play.png"}
            alt={estaTocando ? "Pausar" : "Tocar"}
            onClick={handleCliqueVideo}
          />
        
      </div>
    </div>
  );
}