import { useEffect } from 'react'
import './index.scss'

import { Link, useNavigate } from 'react-router-dom'
import { usuarioEstaLogado } from '../../../../auth'


export default function Chegada() {

   async function BuscarUser(){

    let url = ''



    }




    const navigate = useNavigate();

    useEffect(() => {
        if (!usuarioEstaLogado()) {
            navigate('/login')
        }
    }, [])


   
    return (
        <div className="secao">
            <header>
                <div className='logo'>
                    <img src="assets/images/logofrei_fundo.svg" alt="" />
                </div>
                <div className='texto'>
                    <h1>ADMINISTRADOR</h1>
                </div>
            </header>

            <div className="conteudo">
                <div className="top">
                    <h2>Marcação de Chegada </h2>

                    <h1>Feira de Profissões</h1>
                </div>

                <div className="celular">

                    <div className="input">
                        <div className="sub">
                            <p>Informe o telefone com DDD:</p>

                            <input className='pesq' type="text" />
                            <img className='iconpesq' src="/assets/images/pesquisar.svg" alt="" />
                        </div>
                        <img className='logo' src="/assets/images/logo.png" alt="" />

                    </div>

                </div>



                <div className="tb">
                    <div className="nomes">
                        <p>NOME</p>
                        <p>BAIRRO</p>
                        <p>SITUAÇÃO</p>
                    </div>

                    <div className="informacoes">
                        <p>Robson</p>
                        <p>Vilela rua 5</p>
                        <p>Compareceu</p>
                    </div>

                </div>
            </div>


        </div>


    )
}