import React from "react";

import "./styles.scss";

import Rodape from "../../../src/components/rodape";
import Capa from "../../../src/components/capa";

export default function InscricaoSucesso() {
  return (
    <div className="pagina-sucesso">
      <Capa />

      <div className="container-sucesso">
        <h1 className="titulo-sucesso">
          Cadastro realizado <br /> com <span>sucesso!</span>
        </h1>

        {/* Essa imagem ta com uma marca d'agua !! */}
        <img
          src="/assets/images/inscricao-sucesso-img.svg"
          alt="Imagem de duas pessoas confirmando a inscrição em um evento."
          className="img-sucesso"
        />

        <h3 className="subtitulo-sucesso">
          Esperamos ansiosamente <br /> Sua presença!
        </h3>
      </div>

      <Rodape />
    </div>
  );
}
