import './index.scss';


export default function Rodape() {
    
    return(

        <div className='pagina-rodape'>
            <div className='info-principais'>
                <img src="/assets/images/logo_antiga.png" alt="Logo do Frei" />
            </div>

            <div className='inicio'>
                <h1>INÍCIO</h1>
                <p>Home</p>
                <p>Ingressos</p>
            </div>

            <div className='contatos'>
                <h1>CONTATO</h1>

                <div className='sub1'>
                    <img id='contato-img' src="/assets/images/telefone.contato.png" alt="" />
                    <p>Telefone: (11) 5687-8876</p>
                </div>

                <div className='sub2'>
                    <img id='contato-img' src="/assets/images/carta.contato.png" alt="" />
                    <p>secretaria@acaonsfatima.org.br</p>
                </div>

                <div className='sub3'>
                    <img id='contato-img' src="/assets/images/map.contato.png" alt="" />
                    <p>Av. Cel. Octaviano de Freitas Costa, <br /> 463 - Veleiros São Paulo/SP</p>
                </div>
            </div>

            <div className='redes'>
                <a target='_blank' href="https://www.youtube.com/@institutosocialnossasenhor3548">
                    <i class="fa-brands fa-youtube fa-2xl" id='youtube'></i>
                </a>
                
                <a target='_blank' href="https://www.facebook.com/institutonsfatima/">
                    <i class="fa-brands fa-facebook-f fa-2xl" id='facebook'></i>
                </a>
                
                <a target='_blank' href="https://www.instagram.com/institutonsfatima/">
                    <i class="fa-brands fa-instagram fa-2xl" id='instagram'></i>
                </a>
            </div>
        </div>
    )
}
