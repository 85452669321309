import "./index.scss";

export default function Explore() {

  
  return (
    <div className="comp-expore">
      <img
        className="img-ondas"
        src="./assets/images/ondasLaterais_dir.svg"
        alt="Ondas azuis"
      />

      <h1 className="comp-titulo">Explore com Facilidade !</h1>

      <div className="container-botoes">
        <a href="#secao-video" className="botao" >
          Vídeo
        </a>
        <a href="#secao-informacoes" className="botao">
          informações
        </a>
        <a href="#secao-salas" className="botao">
          salas
        </a>
        <a href="#secao-parcerias" className="botao">
          parcerias
        </a>
      </div>
    </div>
  );
}
