import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from './pages/home';
import Inscricao from "./pages/inscricao";
import Login from "./pages/login";
import Chegada from "./pages/chegada/pages/page1";
import InscricaoSucesso from "./pages/sucesso";



export default function Navegacao() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/inscricao" element={<Inscricao />} />
                <Route path="/chegada" element={<Chegada />} />
                <Route path="/login" element={<Login />} />
                <Route path="/sucesso" element={<InscricaoSucesso />} />
            </Routes>
        </BrowserRouter>
    )
} 