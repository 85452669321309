import axios from "axios";
import { ENDPOINT, TOKEN } from "../../api";

import { useEffect, useState } from "react";
import "./index.scss";

import { withMask } from "use-mask-input";
import Capa from "../../components/capa";
import Rodape from "../../components/rodape";
import { useNavigate } from "react-router-dom";

export default function Inscricao() {
  const [nome, setNome] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nascimento, setDataNascimento] = useState("");

  const navigation = useNavigate();

  useEffect(() => {
    // window.setTimeout(() => {
      document.getElementById('inicio-inscricao').scrollIntoView();
    // }, 500);
  }, [])


  async function fazerInscricao(e) {
    try {
      e.preventDefault();

      var nasc = nascimento.split('/');
      await axios.post(`${ENDPOINT}/inscricao`, { nome, telefone, cep, nascimento: `${nasc[2]}-${nasc[1]}-${nasc[0]}` })
      navigation("/sucesso");
    }
    catch (error) {
      const { erro } = error.response.data;
      alert(erro);
    }
  } 

  return (
    <>
      <div className="capa"><Capa exibirBotãoInscrevase={false} /></div>
      <div className="inscricao-container">
        
        <h1 className="inscricao-titulo" id="inicio-inscricao">INSCRIÇÃO</h1>
        
        <form>
          <div className="container-form">
            <div className="container-input">
              <div className="grupo-input">
                <label htmlFor="nome">Informe seu nome</label>
                <input
                  onChange={(e) => setNome(e.target.value)}
                  placeholder="Nome do visitante"
                  type="text"
                  id="nome"
                  value={nome}
                />
              </div>

              <div className="grupo-input">
                <label htmlFor="CEP">Informe seu CEP</label>
                <input
                  onChange={(e) => setCep(e.target.value)}
                  placeholder="00000-000"
                  type="text"
                  ref={withMask("99999-999")}
                  id="CEP"
                  value={cep}
                />
              </div>
            </div>

            <div className="container-input">
              <div className="grupo-input">
                <label htmlFor="telefone">Informe seu telefone</label>
                <input
                  onChange={(e) => setTelefone(e.target.value)}
                  placeholder="00 00000-0000"
                  ref={withMask("(99) 99999-9999")}
                  type="text"
                  id="telefone"
                  value={telefone}
                />
              </div>

              <div className="grupo-input">
                <label htmlFor="nascimento">Data de nascimento</label>
                <input
                  onChange={(e) => setDataNascimento(e.target.value)}
                  placeholder="00/00/0000"
                  ref={withMask("99/99/9999")}
                  type="text"
                  id="nascimento"
                  value={nascimento}
                />
              </div>
            </div>
          </div>

          <div className="container-button">
            <button onClick={fazerInscricao}>Inscreva-se</button>
          </div>
        </form>
      </div>

      <Rodape />
    </>
  );
}
