import axios from "axios";
import { ENDPOINT, TOKEN } from "./api";

async function entrar(username, password) {
    try {
        const response = await axios.post(`${ENDPOINT}/login/adm?usuario=${username}&senha=${password}`)

        if (response.status == 200) {
            const { token } = response.data;
            localStorage.setItem(TOKEN, token);
        } else {
            console.log(`Erro ocorrido: ${response.status}`)
        }
    } catch (error) {
        const { erro } = error.response.data;
        throw new Error(erro);
    }
}

function usuarioEstaLogado() {
    let token = localStorage.getItem(TOKEN);
    return token != null && token != '';
}

export { entrar, usuarioEstaLogado }