import "./index.scss";
import { useEffect, useState } from "react";

export default function Andares() {
    const [slide1, setSlide1] = useState(0);
    const [slide2, setSlide2] = useState(0);
    const [isPaused1, setIsPaused1] = useState(false);
    const [isPaused2, setIsPaused2] = useState(false);

    const slides = [
        {
            Andar: "P Á T I O",
            Local: "STANDS",
            first_card_content: [
                "Oficina Mecânica de Autos",
                "Oficina Auto Elétrica",
                "Oficina Eletrotécnica",
                "Cuja: Curso pré-vestibular",
                "Casa da Mulher Paulistana", "STB Intercâmbio",
                "Cedesp Ave Maria",
            ],
            second_card_content: [
                "Sala 04: Exame de vista",
                "Sala 06: Inglês",
                "UniÍtalo",
                "Unisa",
                "SP Open Centre",
                "STB Intercâmbio",
            ],
            ball1: "./assets/images/ball dark.png",
            ball2: "./assets/images/ball light.png",
        },
        {
            Andar: "1º A N D A R",
            Local: "SALAS",
            first_card_content: [
                "Sala 18: Administração: Empreendedorismo e Logística",
                "Sala 20: Administração (RH, Contabilidade e Documentos Técnicos)",
            ],
            second_card_content: [],
            ball1: "./assets/images/ball light.png",
            ball2: "./assets/images/ball dark.png",
        },
    ];

    const slides1 = [
        {
            Andar: "2º A N D A R",
            Local: "SALAS",
            first_card_content: [
                "Sala 24: Comunicação Visual",
                "Sala 25: Informática Hardware",
                "Sala 26: Informática Programação",
                "Sala 27: Elaboração de Currículo",
            ],
            second_card_content: [],
            ball1: "./assets/images/ball dark.png",
            ball2: "./assets/images/ball light.png",
        },
        {
            Andar: "3º A N D A R",
            Local: "AUDITÓRIO",
            first_card_content: [
                "⁠CEDESP Ave Maria: O que não pode faltar no RH?",
                "⁠CATe: Indicação para vagas de emprego",
                "⁠ADE Sampa: Atendimento ao Empreendedor",
                "⁠Simulação de Entrevista: Mapfre"
            ],
            second_card_content: [],

            ball1: "./assets/images/ball light.png",
            ball2: "./assets/images/ball dark.png",
        },
    ];



    useEffect(() => {
        if (!isPaused1) {
            const interval2 = setInterval(() => {
                setSlide2((prevSlide) => (prevSlide === 0 ? 1 : 0));
            }, 11000);
            return () => clearInterval(interval2);
        }
    }, [isPaused1]);


    useEffect(() => {
        if (!isPaused2) {
            const interval2 = setInterval(() => {
                setSlide2((prevSlide) => (prevSlide === 0 ? 1 : 0));
            }, 11000);
            return () => clearInterval(interval2);
        }
    }, [isPaused2]);    



    return (
        <div className="container-fluxograma-andares" id="secao-salas">
            <h1 className="title-flux">Confira a nossa Programação</h1>
            <div className="first-card-slide_show"
                onMouseEnter={() => setIsPaused1(true)}
                onMouseLeave={() => setIsPaused1(false)}>
                <div className="name-andar">
                    <h2>{slides[slide1].Andar}</h2>
                    <h1>{slides[slide1].Local}</h1>
                </div>
                <div className="eventos-andar">
                    <h3>Eventos</h3>
                    <div className="lists">
                        <div className="left-list">
                            <ul>
                                {slides[slide1].first_card_content.map((evento, index) => (
                                    <li key={index}>{evento}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="center-list">
                            <ul>
                                {slides[slide1].second_card_content.map((evento, index) => (
                                    <li key={index}>{evento}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="ball-group1">
                        <img
                            onClick={() => setSlide1(0)}
                            className="ball_one1"
                            src={slides[slide1].ball1}
                            alt="Ball 1"
                        />
                        <img
                            onClick={() => setSlide1(1)}
                            className="ball_two2"
                            src={slides[slide1].ball2}
                            alt="Ball 2"
                        />
                    </div>
                </div>
            </div>
            <div className="second-card-slide_show"
                onMouseEnter={() => setIsPaused2(true)}
                onMouseLeave={() => setIsPaused2(false)}>
                <div className="eventos-andar">
                    <h3>Eventos</h3>
                    <div className="lists">
                        <div className="left-list">
                            <ul className="list-second">
                                {slides1[slide2].first_card_content.map((evento, index) => (
                                    <li key={index}>{evento}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="center-list">
                            <ul className="list-second">
                                {slides1[slide2].second_card_content.map((evento, index) => (
                                    <li key={index}>{evento}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="ball-group2">
                        <img
                            onClick={() => setSlide2(0)}
                            className="ball_one1"
                            src={slides1[slide2].ball1}
                            alt="Ball 1"
                        />
                        <img
                            onClick={() => setSlide2(1)}
                            className="ball_two2"
                            src={slides1[slide2].ball2}
                            alt="Ball 2"
                        />
                    </div>
                </div>
                <div className="name-andar">
                    <h2>{slides1[slide2].Andar}</h2>
                    <h1>{slides1[slide2].Local}</h1>
                </div>
            </div>
        </div>
    );
}










